<template>
  <div class="app-dashboard">
    <!-- <div class="row">
      <div class="col-md-2">
        <b-card
          class="height-cards"
          title="Banks"
        >
          <b-card-text>
            {{ totalCounts.banks }}
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-2">
        <b-card
          class="height-cards"
          title="Events"
        >
          <b-card-text>
            {{ totalCounts.events }}
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-2">
        <b-card
          class="height-cards"
          title="Members"
        >
          <b-card-text>
            {{ totalCounts.members }}
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-2">
        <b-card
          class="height-cards"
          title="Transactions"
        >
          <b-card-text>
            {{ totalCounts.transactions }}
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-2">
        <b-card
          class="height-cards"
          title="Transaction Types"
        >
          <b-card-text>
            {{ totalCounts.transactionTypes }}
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-2">
        <b-card
          class="height-cards"
          title="Unions"
        >
          <b-card-text>
            {{ totalCounts.unions }}
          </b-card-text>
        </b-card>
      </div>
    </div> -->
    <b-card>
      <b-card-body>
        <div class="table-responsive">
          <table class="table table-borderless table-striped">
            <tr>
              <td />
              <td
                colspan="2"
                class="text-center font-weight-bolder border-bottom"
              >
                Day
              </td>
              <td
                colspan="2"
                class="text-center font-weight-bolder border-bottom"
              >
                Week
              </td>
              <td
                colspan="2"
                class="text-center font-weight-bolder border-bottom"
              >
                Month
              </td>
            </tr>
            <tr>
              <td class="text-left" />
              <td class="text-center border-bottom">
                Today
              </td>
              <td class="text-center border-bottom">
                Yesterday
              </td>
              <td class="text-center border-bottom">
                Current
              </td>
              <td class="text-center border-bottom">
                Last
              </td>
              <td class="text-center border-bottom">
                Current
              </td>
              <td class="text-center border-bottom">
                Last
              </td>
            </tr>
            <template v-for="(item, index) of dashboardData">
              <!-- eslint-disable-next-line vue/require-v-for-key -->
              <tr
                v-if="index == 4"
              >
                <td colspan="7">
                  <div
                    class="align-items-center flex-grow-1 d-flex justify-content-start"
                  >
                    <p class="my-0 mr-2 font-weight-bolder">
                      Select Union
                    </p>
                    <select
                      v-model="selectedUnion"
                      class="form-control w-25"
                      @change="unionChanged"
                    >
                      <option
                        value="0"
                      >
                        All
                      </option>
                      <option
                        v-for="(data, count) of unionList"
                        :key="count"
                        :value="data.id"
                      >
                        {{ data.name }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr
                :key="index"
                :class="{
                  'font-weight-bolder': index == 0 || index == 4 || index == 9,
                  'border-bottom': index == 3 || index == 8,
                }"
              >
                <td
                  class="text-left"
                  style="width: 100px"
                >
                  <span v-if="index == 0 || index == 4 || index == 9">
                    {{ item.name }}
                  </span>
                  <span v-else>
                    {{ getUsageType(item.usageType) }}
                  </span>
                </td>
                <td class="text-center">
                  {{ $manMudra.actions.toAmount(item.today) }}
                  <span
                    v-if="item.uToday"
                  >&nbsp;({{ $manMudra.actions.toAmount(item.uToday) }})</span>
                </td>
                <td class="text-center">
                  {{ $manMudra.actions.toAmount(item.yesterday) }}
                  <span
                    v-if="item.uYesterday"
                  >&nbsp;({{ $manMudra.actions.toAmount(item.uYesterday) }})</span>
                </td>
                <td class="text-center">
                  {{ $manMudra.actions.toAmount(item.currentWeek) }}
                  <span
                    v-if="item.uCurrentWeek"
                  >&nbsp;({{ $manMudra.actions.toAmount(item.uCurrentWeek) }})</span>
                </td>
                <td class="text-center">
                  {{ $manMudra.actions.toAmount(item.lastWeek) }}
                  <span
                    v-if="item.uLastWeek"
                  >&nbsp;({{ $manMudra.actions.toAmount(item.uLastWeek) }})</span>
                </td>
                <td class="text-center">
                  {{ $manMudra.actions.toAmount(item.currentMonth) }}
                  <span
                    v-if="item.uCurrentMonth"
                  >&nbsp;({{ $manMudra.actions.toAmount(item.uCurrentMonth) }})</span>
                </td>
                <td class="text-center">
                  {{ $manMudra.actions.toAmount(item.lastMonth) }}
                  <span
                    v-if="item.uLastMonth"
                  >&nbsp;({{ $manMudra.actions.toAmount(item.uLastMonth) }})</span>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body>
        <div class="row">
          <div class="col-md-4">
            <input-group
              v-model="messageDto.startDate"
              :value="messageDto.startDate"
              :label="'Start Date'"
              image="/images/members/startDate.svg"
              :type="'date'"
              @input="messageDto.startDate = $event"
            />
          </div>
          <div class="col-md-4">
            <input-group
              v-model="messageDto.endDate"
              :value="messageDto.endDate"
              :label="'End Date'"
              image="/images/members/endDate.svg"
              :type="'date'"
              @input="messageDto.endDate = $event"
            />
          </div>
          <div class="col-md-4 text-left mt-2">
            <button
              class="btn btn-primary"
              style="margin-top: 6px;"
              @click="getMessageLog"
            >
              Search
            </button>
          </div>
          <div class="col-md-12">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Union Id</th>
                  <th>Union Name</th>
                  <th>Message Count</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) of unionMessages"
                  :key="index"
                >
                  <td>
                    {{ item.unionId }}
                  </td>
                  <td>
                    {{ item.union }}
                  </td>
                  <td>
                    {{ item.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import { appDashboardCounts, appGetLogs, appGetUnionMessageCount } from '@/@core/services/groups'
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import eventBus from '@/libs/event-bus'
import InputGroup from '@/@core/layouts/shared/InputGroup.vue'

export default {
  components: {
    BCard,
    BCardBody,
    InputGroup,
  },
  data() {
    return {
      messageDto: {
        startDate: this.$helpers.formatDate(new Date()),
        endDate: this.$helpers.formatDate(new Date().setMonth(new Date().getMonth() + 1)),
      },
      totalCounts: {
        banks: 0,
        events: 0,
        members: 0,
        transactions: 0,
        transactionTypes: 0,
        unions: 0,
      },
      dashboardData: [],
      unionMessages: [],
      selectedUnion: '',
    }
  },
  computed: {
    unionSelected() {
      return this.$store.getters['groups/currentUnion']
    },
    unionList() {
      return this.$store.getters['groups/unionList']
    },
  },
  watch: {
    unionSelected: {
      immediate: true,
      handler() {
        if (this.selectedUnion === '' && this.unionSelected) {
          this.selectedUnion = this.unionSelected.id
        }
      },
    },
  },
  mounted() {
    this.getLogs()
    this.getMessageLog()
  },
  methods: {
    unionChanged() {
      // eslint-disable-next-line eqeqeq
      const item = this.unionList.find(x => x.id == this.selectedUnion)
      if (item) {
        localStorage.setItem(jwtDefaultConfig.unionData, JSON.stringify(item))
        this.$store.commit('groups/UPDATE_SELECTED_UNION', item)
        eventBus.$emit('union-changed', item)
      }
      this.getLogs(this.selectedUnion)
    },
    getMessageLog() {
      showLoader()
      appGetUnionMessageCount(this.messageDto).then(({ data }) => {
        if (data.succeeded) {
          this.unionMessages = data.data
        }
      }).finally(() => {
        hideLoader()
      })
    },
    getLogs(id = null) {
      if (this.unionSelected?.id) {
        this.selectedUnion = this.unionSelected?.id
      } else {
        this.selectedUnion = 0
      }
      if (id) {
        this.selectedUnion = id
      }
      appGetLogs({
        unionId: this.selectedUnion,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.dashboardData = data.data
        }
      })
    },
    getUsageType(type) {
      if (type === 1) return 'Viewed'
      if (type === 2) return 'Add'
      if (type === 3) return 'Updated'
      if (type === 4) return 'Deleted'
      if (type === 5) return 'Status Changed'
      return ''
    },
    initCounter(totalCount, name) {
      if (!this.tempCounter) {
        this.tempCounter = {}
      }
      const random = Math.ceil(Math.random() * 25)
      this.tempCounter[name] = setInterval(() => {
        // eslint-disable-next-line eqeqeq
        if (this.totalCounts[name] > totalCount) {
          this.totalCounts[name] = totalCount
        }
        // eslint-disable-next-line eqeqeq
        if (this.totalCounts[name] != totalCount) {
          this.totalCounts[name] += random
        } else {
          console.log(`${name} cleared`)
          clearInterval(this.tempCounter[name])
        }
      })
    },
    getDashboardCounts() {
      showLoader()
      appDashboardCounts().then(({ data }) => {
        if (data.succeeded) {
          const payload = data.data
          this.totalCounts = payload
          // this.initCounter(payload.banks, 'banks')
          // this.initCounter(payload.events, 'events')
          // this.initCounter(
          //   payload.members,

          //   'members',
          // )
          // this.initCounter(
          //   payload.transactions,

          //   'transactions',
          // )
          // this.initCounter(
          //   payload.transactionTypes,

          //   'transactionTypes',
          // )
          // this.initCounter(payload.unions, 'unions')
        }
        hideLoader()
      })
    },
  },
}
</script>

<style scoped>
.height-cards {
  height: 85%;
}

.height-cards p {
  font-size: 20px;
}
</style>
